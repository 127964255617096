import {Component, OnDestroy, OnInit} from '@angular/core';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {Title} from '@angular/platform-browser';
import {AuthResponseData, AuthUserService} from '../../../Shared/services/auth-user.service';
import {concat, Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {DashboardService} from '../../services/dashboard.service';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {ContactService} from '../../../Shared/services/contact.service';
import {SiteMessagesService} from '../../../Admin/services/site-messages.service';
import {Router} from '@angular/router';
import {ItemFilters} from '../../../Items/pages/items/items.component';
import {
  GetInventoryManagementDetailsQuery,
  GetRecentAuctionsQuery,
  GetUpcomingAuctionsQuery,
  InvoiceSearchRequest, SiteMessageQueryVariables
} from '../../../generated/graphql';
import {CompanySelectionService, UserAggregateCompany, UserCompany} from '../../../Shared/services/company-selection.service';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import {InvoiceService} from '../../../Invoices/services/invoice.service';
import { SearchbarComponent } from 'src/app/Shared/components/searchbar/searchbar.component';
import { DashboardAuctionsComponent } from 'src/app/Dashboard/components/dashboard-auctions/dashboard-auctions.component';
import { SiteMessageAlertComponent } from 'src/app/Admin/components/site-message-alert/site-message-alert.component';
import { DashboardInfoCardComponent } from 'src/app/Dashboard/components/dashboard-info-card/dashboard-info-card.component';
import { InventoryManagementComponent } from 'src/app/Dashboard/components/inventory-management/inventory-management.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [CommonModule, SearchbarComponent, DashboardAuctionsComponent, SiteMessageAlertComponent, DashboardInfoCardComponent, InventoryManagementComponent]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public selectedCompany: (UserCompany | UserAggregateCompany);
  public faTasks = GetFaIcon('tasks', 'fas');
  public faMoneyCheckAlt = GetFaIcon('money-check-alt', 'fas');
  public faGavel = GetFaIcon('gavel', 'fas');
  public faReceipt = GetFaIcon('receipt', 'fas');
  public $user: Subscription;
  public $selectedCompanySubscription: Subscription;
  public user: AuthResponseData;
  public searchbarQuickFilters: Array<DashboardQuickFilters> = [DashboardQuickFilters.ITEMS_SOLD];
  public ytdPayouts: string;
  public estimatedPurchaseBalance: string;
  public currentRegisteredAuctions: number;
  public invoices: any[];
  public upcomingAuctions: GetUpcomingAuctionsQuery['upcomingAuctions'];
  public recentAuctions: GetRecentAuctionsQuery['recentAuctions'];
  public inventoryManagementDetails: GetInventoryManagementDetailsQuery['inventoryManagementDetails'];
  public searchTipText: string;
  public customId: string;
  public loadingSiteMessages: boolean = true;
  public loadingSeller: boolean = true;
  public showSeller: boolean = false;
  public showSellerSub: Subscription;
  public searchType: string;
  public page = 0;
  public searchTerm = '';
  private sellerSearchTipText = 'You can search by Unit #, Item ID, Year, Make, Model, Upper Make, Upper Model, Upper Serial #, VIN, Engine, Fuel, Transmission, or Lot #.';
  private buyerSearchTipText = 'You can search by Unit #, Invoice #, Item ID, Year, Make, Model, Upper Make, Upper Model, Upper Serial #, VIN, Engine, Fuel, Transmission, or Lot #.';
  private readonly destroy$ = new Subject();
  private MAX_PARALLEL_ACCOUNT_IDS = 6;

  constructor(private titleService: Title,
              private userAuth: AuthUserService,
              private dashboardService: DashboardService,
              private contactService: ContactService,
              private companySelection: CompanySelectionService,
              public messageService: SiteMessagesService,
              private googleAnalyticService: GoogleAnalyticService,
              private currencyPipe: CurrencyPipe,
              private invoiceService: InvoiceService,
              private router: Router) {
    this.titleService.setTitle('Dashboard - JJKane');
  }

  ngOnInit(): void {
    this.scanSiteMessages();
    this.showSellerSub = this.companySelection.$showSeller.subscribe(showSeller => this.showSeller = this.companySelection.showSeller.getValue());
    this.$user = this.userAuth.$currentUser.subscribe((usr: AuthResponseData) => {
      this.user = usr;
    });
    this.$selectedCompanySubscription = this.companySelection.$selectedCompany.subscribe(cmp => {
      this.selectedCompany = cmp;
      this.ytdPayouts = null;
      this.upcomingAuctions = null;
      this.recentAuctions = null;
      this.inventoryManagementDetails = {
        notOnSite: null,
        conditionPending: null,
        awaitingTitle: null,
        auctionReady: null,
        assignedItems: null
      };

      const accountIdArray = this.companySelection.getSelectedAccountIds();
      const dashboardRequests: Array<Observable<any>> = [];

      if (this.showSeller) {
        this.searchType = 'seller';
        this.searchTipText = this.sellerSearchTipText;
        if (accountIdArray?.length > this.MAX_PARALLEL_ACCOUNT_IDS) {
          dashboardRequests.push(this.dashboardService.getNotOnSiteCount(accountIdArray).pipe(map(response => this.inventoryManagementDetails.notOnSite = response)));
          dashboardRequests.push(this.dashboardService.getConditionPendingCount(accountIdArray).pipe(map(response => this.inventoryManagementDetails.conditionPending = response)));
          dashboardRequests.push(this.dashboardService.getAwaitingTitleCount(accountIdArray).pipe(map(response => this.inventoryManagementDetails.awaitingTitle = response)));
          dashboardRequests.push(this.dashboardService.getAuctionReadyCount(accountIdArray).pipe(map(response => this.inventoryManagementDetails.auctionReady = response)));
          dashboardRequests.push(this.dashboardService.getAssignedItemsCount(accountIdArray).pipe(map(response => this.inventoryManagementDetails.assignedItems = response)));
          dashboardRequests.push(this.dashboardService.getYearToDatePayouts(accountIdArray).pipe(map(response => this.ytdPayouts = this.currencyPipe.transform(response))));
        } else {
          this.dashboardService.getYearToDatePayouts(accountIdArray).subscribe(response => {
            this.ytdPayouts = this.currencyPipe.transform(response);
          });

          this.dashboardService.getInventoryManagementDetails(accountIdArray).subscribe(response => {
            this.inventoryManagementDetails = response;
          });
        }
      } else {
        this.searchType = 'buyer';
        this.searchTipText = this.buyerSearchTipText;
      }
      var request: InvoiceSearchRequest = {
        buyerAccountIds: this.companySelection.getSelectedAccountIds(),
        page: (this.page + 1),
        pageSize: 10,
        searchTerm: this.searchTerm
      };
      this.invoiceService.searchInvoices(request).subscribe(response => {
        this.invoices = response.content;
      });
      if (accountIdArray?.length > this.MAX_PARALLEL_ACCOUNT_IDS) {
        request.pageSize = 2147483647;
        dashboardRequests.push(this.dashboardService.currentRegisteredAuctions(request).pipe(map(response => this.currentRegisteredAuctions = response)));
        dashboardRequests.push(this.dashboardService.estimatedPurchaseBalance(accountIdArray).pipe(map(response => this.estimatedPurchaseBalance = this.currencyPipe.transform(response))));
        dashboardRequests.push(this.dashboardService.getUpcomingAuctions(accountIdArray).pipe(map(response => this.upcomingAuctions = response)));
        dashboardRequests.push(this.dashboardService.getRecentAuctions(accountIdArray, null).pipe(map(response => this.recentAuctions = response)));
        concat(...dashboardRequests).subscribe();
      } else {
        request.pageSize = 2147483647;
        this.dashboardService.currentRegisteredAuctions(request).subscribe(response => {
          this.currentRegisteredAuctions = response;
        });

        this.dashboardService.estimatedPurchaseBalance(accountIdArray).subscribe(response => {
          this.estimatedPurchaseBalance = this.currencyPipe.transform(response);
        });
        this.dashboardService.getUpcomingAuctions(accountIdArray).subscribe(response => {
          this.upcomingAuctions = response;
        });
        if (this.showSeller) {
          this.dashboardService.getRecentAuctions(accountIdArray, accountIdArray).subscribe(response => {
            this.recentAuctions = response;
          });
        } else {
          this.dashboardService.getRecentAuctions(accountIdArray, null).subscribe(response => {
            this.recentAuctions = response;
          });
        }
      }

      this.loadingSeller = false;
    });
  }

  ngOnDestroy(): void {
    this.$user.unsubscribe();
    this.$selectedCompanySubscription.unsubscribe();
    this.showSellerSub.unsubscribe();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public scanSiteMessages(): void {
    this.loadingSiteMessages = true;
    const scanMessages: SiteMessageQueryVariables = {
      accountID: this.messageService.accountID
    };
    this.messageService.scanSiteMessages(scanMessages).subscribe(res => {
      if (res) {
        this.messageService.messages = res;
        this.messageService.sortMessages();
      }
      this.loadingSiteMessages = false;
    });
  }

  public getWelcomeMessage(): string {
    return `Welcome, ${this.user.firstName} ${this.user.lastName}`;
  }

  public quickFilter(quickFilter: DashboardQuickFilters): void {
    let event: GoogleAnalyticEventObject = {
      category: 'Quick Filter',
      action: quickFilter,
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      switch (quickFilter) {
        case DashboardQuickFilters.ITEMS_SOLD:
          this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.STATUS]: 'Sold'}});
          break;
        case DashboardQuickFilters.NOT_ON_SITE:
          this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.INVENTORY_MANAGEMENT]: 'Not On Site'}});
          break;
        case DashboardQuickFilters.CONDITION_PENDING:
          this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.INVENTORY_MANAGEMENT]: 'Condition Pending'}});
          break;
        case DashboardQuickFilters.AWAITING_TITLE:
          this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.INVENTORY_MANAGEMENT]: 'Awaiting Title'}});
          break;
        case DashboardQuickFilters.AUCTION_READY:
          this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.INVENTORY_MANAGEMENT]: 'Auction Ready'}});
          break;
        default:
          break;
      }
    });
  }

  public search(term: string): void {
    const event: GoogleAnalyticEventObject = {
      category: '',
      action: 'Click',
      label: 'Dashboard'
    };

    if (this.searchType === 'seller') {
      event.category = 'Consigned Items Search';
      this.googleAnalyticService.logAnalyticEvent(event, () => {
        this.router.navigate(['dashboard/items'], {queryParams: {Search: term}});
      });
    } else {
      event.category = 'Purchased Items Search';
      this.googleAnalyticService.logAnalyticEvent(event, () => {
        this.router.navigate(['dashboard/purchased-items'], {queryParams: {Search: term}});
      });
    }
  }

  public submittedItemsClicked(): void {
    const event: GoogleAnalyticEventObject = {
      category: 'Submitted Items Card',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.router.navigate(['dashboard/items'], {queryParams: {[ItemFilters.STATUS]: 'Submitted'}});
    });
  }

  public ytdPayoutsClicked(): void {
    const event: GoogleAnalyticEventObject = {
      category: 'YTD Payouts Card',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.router.navigate(['dashboard/payouts']);
    });
  }

  public estimatedPurchaseBalanceClicked(): void {
    const event: GoogleAnalyticEventObject = {
      category: 'Estimated Purchase Balance Card',
      action: 'Click',
      label: 'Dashboard'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.router.navigate(['dashboard/invoices']);
    });
  }

  public toggleItemSearchType(searchType: string): void {
    this.searchType = searchType;
    this.searchTipText = searchType === 'seller' ? this.sellerSearchTipText : this.buyerSearchTipText;
  }
}

export enum DashboardQuickFilters {
  ITEMS_SOLD = 'View Items Sold',
  NOT_ON_SITE = 'Not On Site',
  CONDITION_PENDING = 'Condition Pending',
  AWAITING_TITLE = 'Awaiting Title',
  AUCTION_READY = 'Auction Ready'
}
