import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { StateUs } from '../../../../Submit Items/models/state-us';
import { GetCountriesQuery, BusinessCode, GeocodeLocation } from '../../../../generated/graphql';
import { RegistrationService } from '../../../services/registration.service';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, MatTableModule, MatRadioModule],
})
export class BusinessComponent implements OnInit {

  @Input() public showForm: boolean;
  @Input() public parentForm: FormGroup;
  @Input() public loading: boolean;
  @Input() public states: StateUs[];
  @Input() public countries: GetCountriesQuery['countries'];
  @Input() public businessCodes: BusinessCode[];

  public form: FormGroup;

  public geocodeLocations: GeocodeLocation[] = [];
  public selectedLocation: GeocodeLocation;
  public geocodeLoading: boolean = false;

  public columnsToDisplay = ['select', 'city', 'county', 'state'];

  constructor(private fb: FormBuilder, private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      businessName: ['', Validators.required],
      businessType: ['', Validators.required],
      businessPhoneNumber: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      businessPhoneType: ['', Validators.required],
      businessAddressLine1: ['', Validators.required],
      businessAddressLine2: [''],
      businessCity: ['', Validators.required],
      businessState: ['', Validators.required],
      businessZip: ['', [Validators.required, Validators.pattern('^[\\d]{5}$')]],
      businessCounty: [''],
      businessCountry: ['', Validators.required]
    });

    this.parentForm.addControl('businessGroup', this.form);
  }

  public checkForValidZip() {
    if(this.form.controls["businessZip"].valid) {
      this.geocodeLoading = true;
      this.geocodeLocations = [];
      this.registrationService.geocode(this.form.controls["businessZip"].value).subscribe(result => {
        if(result.success) {
          this.geocodeLocations = result.objects;
          this.geocodeLoading = false;
        }
      });
    }
  }

  public selectLocation(location: GeocodeLocation) {
    this.selectedLocation = location;

    this.form.controls["businessCity"].setValue(location.city);
    this.form.controls["businessCounty"].setValue(location.county);
    this.form.controls["businessState"].setValue(location.state);
  }

  public formatPhone(event) {
    if(event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) { return; }

    let phoneNumber = event.target.value;
		phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

		if(phoneNumber.length == 0) {
			phoneNumber = "";
		} else if(phoneNumber.length < 3) {
			phoneNumber = "(" + phoneNumber;
		} else if(phoneNumber.length == 3) {
			phoneNumber = "(" + phoneNumber + ") ";
		} else if(phoneNumber.length < 7) {
			phoneNumber = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, phoneNumber.length);
		} else if(phoneNumber.length >= 7) {
			phoneNumber = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, phoneNumber.length);
		}

		this.form.controls["businessPhoneNumber"].setValue(phoneNumber);
  }
}
