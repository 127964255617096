import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BusinessWizardComponent } from '../components/business-wizard/business-wizard.component';
import { PersonalWizardComponent } from '../components/personal-wizard/personal-wizard.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  imports: [CommonModule, BusinessWizardComponent, PersonalWizardComponent]
})

export class RegistrationComponent implements OnInit {

  public showBusinessWizard = false;
  public showPersonalWizard = false;

  constructor(private titleService: Title) {
    this.titleService.setTitle('Registration - JJKane');
  }

  ngOnInit(): void {
  }

}
