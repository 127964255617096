import { CommonModule } from '@angular/common';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dashboard-info-card',
  templateUrl: './dashboard-info-card.component.html',
  styleUrls: ['./dashboard-info-card.component.scss'],
  imports: [CommonModule, FaIconComponent]
})
export class DashboardInfoCardComponent implements OnInit {

  @Input()
  icon: IconDefinition;

  @Input()
  title: string;

  @Input()
  data: string;

  @Input()
  color: string;

  @Output()
  clicked: EventEmitter<null> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public getStyleClass(): string {
    return this.color;
  }
}
