import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { ErrorInterceptor } from './app/Shared/interceptors/error.interceptor';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { APOLLO_OPTIONS, APOLLO_NAMED_OPTIONS, NamedOptions, provideApollo } from 'apollo-angular';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFilesizeModule } from 'ngx-filesize';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatTableModule } from '@angular/material/table';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { AppRoutes } from './app/app-routes';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      MatSidenavModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatRadioModule,
      AutocompleteLibModule,
      NgbModule,
      FontAwesomeModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      UiSwitchModule,
      NgxFileDropModule,
      NgxFilesizeModule,
      RecaptchaModule,
      RecaptchaFormsModule,
      AuthModule.forRoot({
        domain: environment.auth0Config.domain,
        clientId: environment.auth0Config.clientId,
        authorizationParams: {
          audience: environment.auth0Config.audience,
          scope: 'portal-user:seller',
          connection: 'JJK-AIM',
          theme: 'JJK',
          jjkEnv: environment.envName,
          redirect_uri: `${document.location.origin}/`,
          prompt: 'login'
        },
        cacheLocation: 'localstorage',
        httpInterceptor: {
            allowedList: [
                environment.aimApi + '/*',
                environment.graphQLConfig.apiUrl,
                environment.graphQLConfig.test + '*',
                environment.graphQLConfig.downloadItemDetailImagesUrl + '*',
                environment.graphQLConfig.downloadPayoutReportUrl + '*'
            ],
        }
      }),
      MatTableModule
    ),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphQLConfig.apiUrl
          }),
        };
      },
      deps: [HttpLink]
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpBackend: HttpBackend): NamedOptions {
        return {
          noAuthClient: {
            cache: new InMemoryCache(),
            link: new HttpLinkHandler(new HttpClient(httpBackend), { uri: environment.graphQLConfig.apiUrl })
          }
        };
      },
      deps: [HttpBackend]
    },
    CurrencyPipe,
    DatePipe,
    provideHttpClient(),
    provideApollo(() => {
      const httpLink = inject(HttpLink);

      return {
        link: httpLink.create({
          uri: environment.graphQLConfig.apiUrl,
        }),
        cache: new InMemoryCache(),
      };
    }),
    provideRouter(AppRoutes)
  ]
}).catch(err => console.error(err));
