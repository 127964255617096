import { Routes } from '@angular/router';
import { DashboardComponent } from './Dashboard/pages/dashboard/dashboard.component';
import { ItemsComponent } from './Items/pages/items/items.component';
import { PayoutComponent } from './Payouts/pages/payouts/payout.component';
import { SubmitItemComponent } from './Submit Items/pages/submit-item/submit-item.component';
import { ContactUsComponent } from './Shared/components/contact-us/contact-us.component';
import {ItemDetailComponent} from './Items/pages/item-detail/item-detail.component';
import { AuthGuard } from '@auth0/auth0-angular';
import {GraphqlComponent} from './Devtools/pages/graphql/graphql.component';
import {ToolsEnabledGuard} from './Devtools/guards/tools-enabled.guard';
import {LoginComponent} from './Login/pages/login/login.component';
import {IndexComponent} from './Dashboard/pages/index/index.component';
import { AccountDetailsComponent } from './Account/pages/account-details/account-details.component';
import {DraftsComponent} from './item-drafts/pages/drafts/drafts.component';
import { RegistrationComponent } from './Registration/pages/registration.component';
import { AdminComponent } from './Admin/pages/admin/admin.component';
import { IsUserAdminGuard } from './Admin/guards/is-user-admin.guard';
import { ForgotPasswordComponent } from './Password/pages/forgot-password/forgot-password.component';
import { BaseLayoutComponent } from './Shared/components/base-layout/base-layout.component';
import {ActivationComponent} from './Registration/pages/activation/activation.component';
import {PayoutDetailComponent} from './Payouts/pages/payout-detail/payout-detail.component';
import {InvoiceComponent} from './Invoices/pages/invoice/invoice.component';

export const AppRoutes: Routes = [
  {
    pathMatch: 'full',
    path: '',
    redirectTo: 'dashboard'
  },
  {
    path: 'registration',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: RegistrationComponent
      }]
  },
  {
    path: 'portal/activation/:token',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: ActivationComponent
      }]
  },
  {
    path: 'graphQl',
    component: BaseLayoutComponent,
    canActivate: [ToolsEnabledGuard],
    children: [
      {
        path: '',
        component: GraphqlComponent,
      }]
  },
  {
    path: 'forgotpassword',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: ForgotPasswordComponent
      }]
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: IndexComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'item-drafts',
        component: DraftsComponent,
      },
      {
        path: 'items',
        component: ItemsComponent,
      },
      {
        path: 'items/details/:itemId',
        component: ItemDetailComponent,
      },
      {
        path: 'purchased-items',
        component: ItemsComponent,
      },
      {
        path: 'purchased-items/:itemId',
        component: ItemDetailComponent,
      },
      {
        path: 'payouts',
        component: PayoutComponent,
      },
      {
        path: 'payouts/:payoutId',
        component: PayoutDetailComponent,
      },
      {
        path: 'invoices',
        component: InvoiceComponent,
      },
      {
        path: 'submit-items',
        component: SubmitItemComponent,
      },
      {
        path: 'account',
        component: AccountDetailsComponent,
      },
      {
        path: 'graphQl',
        component: GraphqlComponent,
        canActivate: [ToolsEnabledGuard]
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [IsUserAdminGuard]
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];
