import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ErrorModalComponent } from '../../../Shared/components/error-modal/error-modal.component';

@Component({
  selector: 'app-submit-modal',
  templateUrl: './submit-modal.component.html',
  imports: [CommonModule, ErrorModalComponent, MatProgressBarModule]
})

export class SubmitModalComponent implements OnInit {

  public photoCount: number = 0;
  public doneSubmitting: boolean = false;
  public submittingItem: boolean = false;
  public submittingPhotos: boolean = false;
  public error: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
