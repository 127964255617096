import { Component, OnInit } from '@angular/core';
import { IndexComponent } from '../../../Dashboard/pages/index/index.component';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  imports: [IndexComponent]
})
export class BaseLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
