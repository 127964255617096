import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NavigationStepData } from '../../../Submit Items/models/navigation-step-data';
import { RegistrationService } from '../../services/registration.service';
import { CommonService } from '../../../Shared/services/common.service';
import { StateUs } from '../../../Submit Items/models/state-us';
import { forkJoin } from 'rxjs';
import { GetFaIcon } from '../../../../../src/app/Shared/functions/icon-helpers';
import { BusinessCode, Country } from '../../../generated/graphql';
import { RegistrationBaseStepWizardComponent } from '../registration-base-step-wizard/registration-base-step-wizard.component';
import { PersonalComponent } from '../steps/personal/personal.component';
import { BusinessComponent } from '../steps/business/business.component';
import { PasswordComponent } from '../steps/password/password.component';
import { SuccessComponent } from '../steps/success/success.component';
import { FailureComponent } from '../steps/failure/failure.component';

@Component({
  selector: 'app-business-wizard',
  templateUrl: './business-wizard.component.html',
  styleUrls: ['./business-wizard.component.scss'],
  imports: [
    RegistrationBaseStepWizardComponent,
    PersonalComponent,
    BusinessComponent,
    PasswordComponent,
    SuccessComponent,
    FailureComponent
  ]
})
export class BusinessWizardComponent implements OnInit {

  public parentForm: FormGroup;
  public selectedStep = 1;
  public loadingStates = true;
  public loadingCountries = true;
  public loadingCodes = true;
  public loadingData = true;
  public states: StateUs[];
  public countries: Country[];
  public businessCodes: BusinessCode[];

  public navigationSteps: NavigationStepData[] = [
    {
      title: 'Personal Info',
      icon: GetFaIcon('user', 'fas')
    },
    {
      title: 'Business Info',
      icon: GetFaIcon('building', 'fas')
    },
    {
      title: 'Password',
      icon: GetFaIcon('lock', 'fas')
    }
  ];

  constructor(private fb: FormBuilder, private regestrationService: RegistrationService, private commonService: CommonService) {
    this.parentForm = this.fb.group({});
  }

  ngOnInit(): void {
      this.commonService.getStatesUSList().subscribe((states) => {
        this.states = states;
        this.loadingStates = false;
        this.loadCheck();
      });
      this.commonService.getCountries().subscribe((countries) => {
        this.countries = countries;
        this.loadingCountries = false;
        this.loadCheck();
      });
      this.regestrationService.getBusinessCodes().subscribe((businessCodes) => {
        this.businessCodes = businessCodes;
        this.loadingCodes = false;
        this.loadCheck();
      });
  }

  public setStep(step: number): void {
    this.selectedStep = step;
  }

  public loadCheck(): void {
    this.loadingData = this.loadingStates || this.loadingCountries || this.loadingCodes;
  }

}
