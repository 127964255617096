import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Item } from '../../../generated/graphql';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-misc-fees-modal',
  templateUrl: './misc-fees-modal.component.html',
  styleUrls: ['./misc-fees-modal.component.scss'],
  imports: [CurrencyPipe]
})
export class MiscFeesModalComponent implements OnInit {
  @Input() itemDetail: Item;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
