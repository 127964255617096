import { CommonModule } from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'inventory-management-card',
  templateUrl: './inventory-management-card.component.html',
  styleUrls: ['./inventory-management-card.component.scss'],
  imports: [CommonModule]
})
export class InventoryManagementCardComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  count: number;

  @Output()
  clicked: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public quickFilterClicked(): void {
    this.clicked.emit(this.title);
  }
}
