import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GetFaIcon } from '../../functions/icon-helpers';
import { GoogleAnalyticDirective } from '../../directives/google-analytic.directive';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  imports: [CommonModule, FaIconComponent, GoogleAnalyticDirective]
})
export class ImageCarouselComponent implements OnInit {

  noImagePlaceholderUrl: string[] = ['../../../../../assets/images/300px-No_image_available.svg.png'];

  private _imageUrls: Array<string> = [];

  @Input()
  set imageUrls(imageUrls: Array<string>) {
    if (imageUrls && imageUrls.length > 0) {
      this._imageUrls = imageUrls;
    } else {
      this._imageUrls = this.noImagePlaceholderUrl;
    }

    this.thumbnailUrls = this.imageUrls.slice(this.startPreviewGalleryIndex, this.startPreviewGalleryIndex + this.numberOfImagesToDisplay);
    if(this.imageUrls.length < this.numberOfImagesToDisplay) {
      const fillIns = this.numberOfImagesToDisplay - this.imageUrls.length;
      const temp = new Array<string>(fillIns);
      temp.fill('');
      this.thumbnailUrls = this.thumbnailUrls.concat(temp);
    }
  }

  get imageUrls(): Array<string> {
    return this._imageUrls;
  }

  @Input()
  numberOfImagesToDisplay: number = 4;

  @Input()
  selectedIndex: number = 0;

  @Input()
  loading: boolean = false;

  @Output()
  download: EventEmitter<null> = new EventEmitter();

  public startPreviewGalleryIndex: number = 0;
  public startPreviewGalleryIndexWhenSelected: number = 0;
  public thumbnailUrls: Array<string> = [];

  public faChevronCircleRight = GetFaIcon('chevron-circle-right', 'fas');
  public faChevronCircleLeft = GetFaIcon('chevron-circle-left', 'fas');


  constructor() { }

  ngOnInit(): void {}

  public previousImage(): void{
    if (this.startPreviewGalleryIndex > 0){
      this.startPreviewGalleryIndex--;
      this.thumbnailUrls = this.imageUrls.slice(this.startPreviewGalleryIndex, this.startPreviewGalleryIndex + this.numberOfImagesToDisplay);
    }
  }

  public nextImage(): void{
    if ((this.startPreviewGalleryIndex + this.numberOfImagesToDisplay) < this.imageUrls.length){
      this.startPreviewGalleryIndex++;
      this.thumbnailUrls = this.imageUrls.slice(this.startPreviewGalleryIndex, this.startPreviewGalleryIndex + this.numberOfImagesToDisplay);
    }
  }

  public selectImage(index: number): void{
    this.selectedIndex = index+this.startPreviewGalleryIndex;
    this.startPreviewGalleryIndexWhenSelected = this.startPreviewGalleryIndex;
  }

  public downloadButton() {
    this.download.emit();
  }

}
