import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {IItem, ILocation, IUnit, IVehicle} from '../../../models/form-models';
import {FormGroup} from '@angular/forms';
import {SubmitItemService} from '../../../services/submit-item.service';
import {Observable} from 'rxjs';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';
import { CommonModule } from '@angular/common';
import { NgxFilesizeModule } from 'ngx-filesize';

@Component({
  selector: 'app-confirmation-step',
  templateUrl: './confirmation-step.component.html',
  styleUrls: ['./confirmation-step.component.scss'],
  imports: [CommonModule, NgxFilesizeModule],
})
export class ConfirmationStepComponent implements OnInit, OnChanges {

  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  @Input() public draft: RDSDraftItem;
  @Input() public parentForm: FormGroup;
  public item: IItem;
  public vehicle: IVehicle;
  public unit: IUnit;
  public location: ILocation;

  constructor(public submitItemService: SubmitItemService) {
  }

  private _showForm: boolean;

  get showForm(): boolean {
    return this._showForm;
  }

  @Input()
  set showForm(showForm: boolean) {
    if (this.draft){
        this.parseDraftValue();
    } else {
        this.parseFormValues();
    }
    this._showForm = showForm;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
      this.parseFormValues();
  }

  parseDraftValue(): void {
    this.vehicle = {
      category: this.draft.parentCategory,
      subcategory: this.draft.category,
      vin: this.draft.vin,
      year: this.draft.year,
      make: this.draft.make,
      model: this.draft.model,
      unitNumber: this.draft.unitNumber,
      sellerVehicleNumber: this.draft.sellerFleetNumber2,
      licensePlateNumber: this.draft.plateNumber,
      engine: this.draft.engine,
      fuel: this.draft.fuel,
      transmission: this.draft.transmission,
      odometer: this.draft.odometer,
      hours: this.draft.hours,
      optionalEquipment: this.draft.optionalEquipment,
      airConditioning: this.draft.airConditioning
    };

    this.unit = {
      serialNumber: this.draft.sellerFleetNumber2,
      make: this.draft.upperMake,
      model: this.draft.upperModel,
      fuel: this.draft.upperFuel,
      height: this.draft.height,
      capacity: this.draft.capacity,
      capacityUnit: this.draft.capacityUnit,
      mounted: this.draft.mounted,
      optionalEquipment: this.draft.optionalEquipment,
      notes: this.draft.itemNotes
    };

    this.location = {
      addressLine1: this.draft.offSitePhysicalAddress,
      city: this.draft.offSitePhysicalCity,
      state: this.draft.offSitePhysicalState,
      zip: this.draft.offSitePhysicalZip,
      county: this.draft.taxCounty
    };
  }

  parseFormValues(): void {
    if (this.parentForm.controls.vehicleInfoFormGroup) {
      this.vehicle = {...this.parentForm.controls.vehicleInfoFormGroup.value};
    }

    if (this.parentForm.controls.vehicleSpecsFormGroup) {
      this.vehicle = {...this.vehicle, ...this.parentForm.controls.vehicleSpecsFormGroup.value};
    }

    if (this.parentForm.controls.unitInfoFormControlGroup) {
      this.unit = {...this.parentForm.controls.unitInfoFormControlGroup.value};
    }

    if (this.parentForm.controls.locationFormGroup) {
      this.location = {...this.parentForm.controls.locationFormGroup.value};
    }

    if (this.parentForm.controls.itemInfoFormGroup) {
      this.item = {...this.parentForm.controls.itemInfoFormGroup.value};
    }
  }
}
