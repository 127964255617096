import {Component} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {CompanySelectionService} from './Shared/services/company-selection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule]
})
export class AppComponent {
  constructor(private router: Router, private companySelectionService: CompanySelectionService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        if (event.url === '/dashboard/submit-items' || event.url === '/dashboard/submit-items?Draft=true'){
          this.companySelectionService.showCompanyDropdown = false;
        }else {
          this.companySelectionService.showCompanyDropdown = true;
        }
      }
    });
  }
}
