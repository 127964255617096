import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {SubmitItemService} from '../../../services/submit-item.service';
import {Subcategory} from '../../../models/submit-item-gql-models';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import {Observable} from 'rxjs';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-item-info-step',
  templateUrl: './item-info-step.component.html',
  styleUrls: ['./item-info-step.component.scss'],
  imports: [CommonModule, ReactiveFormsModule]
})
export class ItemInfoStepComponent implements OnInit {

  @Input() public showForm: boolean;
  @Input() public parentForm: FormGroup;
  @Input() public dropdownValues: GetDropdownValuesQuery;
  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;
  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  public form: FormGroup;
  public years: string[] = [];
  public filteredSubcategories: GetDropdownValuesQuery['subcategories'];
  public models: string[] = [];
  public loading = true;

  constructor(private fb: FormBuilder,
              private submitItemService: SubmitItemService
  ) {
  }

  ngOnInit(): void {
    this.years = this.submitItemService.getYearList();
    this.form = this.fb.group({
      category: [''],
      subcategory: [{value: '', disabled: true}],
      year: [''],
      makeAndModel: [''],
      make: [''],
      model: [{value: '', disabled: true}],
      unitNumber: ['', Validators.maxLength(20)],
      optionalEquipment: ['', Validators.maxLength(1000)]
    });

    this.ddvSubject.subscribe(values => {
      this.dropdownValues = values;
      this.loading = values == null;
      if (!this.loading && this.draft) {
        this.form.setValue({
          category: this.draft.parentCategory,
          subcategory: this.draft.category,
          year: this.draft.year,
          makeAndModel: this.draft.make ? this.dropdownValues.makeAndModel.find(filter => filter.make === this.draft.make) : null,
          make: this.draft.make ? this.dropdownValues.makeAndModel.find(filter => filter.make === this.draft.make).make : null,
          model: this.draft.model,
          unitNumber: this.draft.unitNumber,
          optionalEquipment: this.draft.optionalEquipment
        });
      }
    });

    this.form.get('category').valueChanges.subscribe(category => {
      const subcategoryControl = this.form.get('subcategory');
      subcategoryControl.setValue('');
      if (category) {
        this.filteredSubcategories = this.dropdownValues.subcategories
          .filter(x => x.parentCategoryName === category);
        subcategoryControl.enable();
      } else {
        subcategoryControl.disable();
      }
    });

    this.form.get('makeAndModel').valueChanges.subscribe(value => {
      this.form.get('make').setValue('');
      this.form.get('model').setValue('');
      if (value) {
        this.form.get('make').setValue(value.make);
        this.models = value.models;
        this.form.get('model').enable();
      } else {
        this.form.get('model').disable();
      }
    });

    this.parentForm.addControl('itemInfoFormGroup', this.form);
  }
}
