import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NavigationStepData } from '../../../Submit Items/models/navigation-step-data';
import { GetFaIcon } from '../../../../../src/app/Shared/functions/icon-helpers';
import { StateUs } from '../../../Submit Items/models/state-us';
import { CommonService } from '../../../Shared/services/common.service';
import { forkJoin } from 'rxjs';
import { GetCountriesQuery } from '../../../generated/graphql';
import { RegistrationBaseStepWizardComponent } from '../registration-base-step-wizard/registration-base-step-wizard.component';
import { PersonalComponent } from '../steps/personal/personal.component';
import { PasswordComponent } from '../steps/password/password.component';
import { SuccessComponent } from '../steps/success/success.component';
import { FailureComponent } from '../steps/failure/failure.component';
@Component({
  selector: 'app-personal-wizard',
  templateUrl: './personal-wizard.component.html',
  styleUrls: ['./personal-wizard.component.scss'],
  imports: [
    RegistrationBaseStepWizardComponent,
    PersonalComponent,
    PasswordComponent,
    SuccessComponent,
    FailureComponent
  ]
})
export class PersonalWizardComponent implements OnInit {

  public parentForm: FormGroup;
  public selectedStep = 1;
  public loadingStates = true;
  public loadingCountries = true;
  public loadingData = true;
  public states: StateUs[];
  public countries: GetCountriesQuery['countries'];

  public navigationSteps: NavigationStepData[] = [
    {
      title: 'Personal Info',
      icon: GetFaIcon('user', 'fas')
    },
    {
      title: 'Password',
      icon: GetFaIcon('lock', 'fas')
    }
  ];

  constructor(private fb: FormBuilder, private commonService: CommonService) {
    this.parentForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.commonService.getStatesUSList().subscribe((states) => {
      this.states = states;
      this.loadingStates = false;
      this.loadCheck();
    });
    this.commonService.getCountries().subscribe((countries) => {
      this.countries = countries;
      this.loadingCountries = false;
      this.loadCheck();
    });
  }

  public setStep(step: number): void {
    this.selectedStep = step;
  }

  public loadCheck(): void {
    this.loadingData = this.loadingStates || this.loadingCountries;
  }
}
