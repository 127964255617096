import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { GetFaIcon } from '../../../../Shared/functions/icon-helpers';
import { SubmitItemService } from '../../../services/submit-item.service';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import {environment} from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';


@Component({
  selector: 'app-pictures-step',
  templateUrl: './pictures-step.component.html',
  styleUrls: ['./pictures-step.component.scss'],
  imports: [CommonModule, NgxFileDropModule]
})
export class PicturesStepComponent implements OnInit {
  @Input() public showForm: boolean;
  @Input() public parentForm: FormGroup;
  @Input() public draft: RDSDraftItem;
  public form: FormGroup;
  public uploadQueue: EnhancedFile[] = [];
  public faTimesCircle = GetFaIcon('times-circle', 'fas');

  constructor(public submitService: SubmitItemService) { }

  ngOnInit(): void {
    this.submitService.uploadQueue = [];
  }

  public dropped(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const enhancedFile = new EnhancedFile();
        enhancedFile.fileEntry = droppedFile;

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          enhancedFile.size = file.size;
          this.submitService.uploadQueue.push(file);
        });
      }
    }
  }

  public removeFile(file: File): void {
    const index = this.submitService.uploadQueue.findIndex(x => x === file);
    this.submitService.uploadQueue.splice(index, 1);
  }

  public removeAll(): void {
    this.submitService.uploadQueue = [];
  }

  get instructionPdfUrl(): string {
    return `${environment.connectStaticsUrl}JJK Online Timed Auction Photo Procedure.pdf`;
  }
}

export class EnhancedFile {
  size: number;
  fileEntry: NgxFileDropEntry;
}
