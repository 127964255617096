import { Component, OnInit } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService) {
    // this.authService.loginWithRedirect({ appState: { prompt: "login" }});
    const redirectUri = `${document.location.origin}/`;
    this.authService.loginWithRedirect({
      authorizationParams: {
        scope: 'portal-user:seller',
        connection: 'JJK-AIM',
        theme: 'JJK',
        jjkEnv: environment.envName,
        redirect_uri: redirectUri,
        prompt: 'login'
      },
      appState: { prompt: "login" }
    });
  }

  ngOnInit(): void {
  }

}
