import {Component, OnInit, ViewChild} from '@angular/core';
import {
  SearchbarComponent,
  SortFilterData,
  SortFilterOutput
} from '../../../Shared/components/searchbar/searchbar.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DraftResponse, DraftsService} from '../../services/drafts.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { DraftCardComponent } from '../../components/draft-card/draft-card.component';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss'],
  imports: [CommonModule, SearchbarComponent, DraftCardComponent],
})
export class DraftsComponent implements OnInit {
  @ViewChild('searchbar', {static: true})
  public searchbarComponent: SearchbarComponent;

  public page = 0;
  public appliedSortFilters: SortFilterOutput;
  public selectedCompanySub: Subscription;
  public loading = true;
  public draftItems: DraftResponse = {
    results: []
  };
  public accountId: number[];
  public hasMore = false;
  public searchTerm = '';

  public sortFilter: SortFilterData = {
    hasFilterOptions: true,
    hasSortOptions: true,
    sortData: [
      {
        title: ItemSorts.CATEGORY,
        backEndTitle: 'category'
      },
      {
        title: ItemSorts.MAKE,
        backEndTitle: 'make'
      },
      {
        title: ItemSorts.MODEL,
        backEndTitle: 'model'
      },
      {
        title: ItemSorts.UPPER_MAKE,
        backEndTitle: 'upperMake'
      },
      {
        title: ItemSorts.UPPER_MODEL,
        backEndTitle: 'upperModel'
      }
    ],
    filterData: [
      {
        title: ItemFilters.CATEGORY,
        options: [],
        backEndTitle: 'category'
      },
      {
        title: ItemFilters.MAKE,
        options: [],
        backEndTitle: 'make'
      },
      {
        title: ItemFilters.MODEL,
        options: [],
        backEndTitle: 'model'
      },
      {
        title: ItemFilters.UPPER_MAKE,
        options: [],
        backEndTitle: 'upperMake'
      },
      {
        title: ItemFilters.UPPER_MODEL,
        options: [],
        backEndTitle: 'upperModel'
      }
    ]
  };

  constructor(
    private titleService: Title,
    private draftsService: DraftsService,
    private companySelection: CompanySelectionService,
    private router: Router,
    private route: ActivatedRoute) {
    this.titleService.setTitle('Item Drafts - JJKane');
  }

  ngOnInit(): void {
    this.draftsService.getUserFilterOptions(this.companySelection.getSelectedAccountIds()).subscribe(items => {
      const makeArray = items.make.filter(el => {
        return el != null && el != '';
      });
      this.sortFilter.filterData[1].options = makeArray;

      const catArray = items.category.filter(el => {
        return el != null && el != '';
      });
      this.sortFilter.filterData[0].options = catArray;

      const modelArray = items.model.filter(el => {
        return el != null && el != '';
      });
      this.sortFilter.filterData[2].options = modelArray;

      const uMakeArray = items.upperMake.filter(el => {
        return el != null && el != '';
      });
      this.sortFilter.filterData[3].options = uMakeArray;

      const uModelArray = items.upperModel.filter(el => {
        return el != null && el != '';
      });
      this.sortFilter.filterData[4].options = uModelArray;

      this.hasMore = false;

      this.selectedCompanySub = this.companySelection.$selectedCompany.subscribe(cmp => {
        if (cmp) {
          this.accountId = this.companySelection.getSelectedAccountIds();
        }
        this.route.queryParams.pipe(first()).subscribe(queryParams => {
          setTimeout(() => {
            let triggerShowFilterBar = false;

            this.appliedSortFilters = {};

            if(queryParams.Search){
              this.searchTerm = queryParams.Search;
              this.appliedSortFilters.searchTerm = queryParams.Search;
              this.searchbarComponent.setSearchText(queryParams.Search);
              triggerShowFilterBar = true;
            }

            this.page = +queryParams.page || 0;

            const paramFilters = [];

            for (const [param, value] of Object.entries(queryParams)) {
              if (param === 'Sort') {
                const sortData = this.sortFilter.sortData.find(el => el.title === value);
                this.searchbarComponent.selectSortProgramatically(String(value), sortData.backEndTitle);
                triggerShowFilterBar = true;
              } else if (param === 'Direction') {
                this.searchbarComponent.sortDirectionChange(String(value));
                triggerShowFilterBar = true;
              } else if (param === 'Make'){
                paramFilters.push({
                  filter: 'Make',
                  selection: String(value),
                  backEndFilter: 'make'
                });
                this.searchbarComponent.selectFilterProgramatically('Make', String(value), 'make');
              } else if (param === 'Model'){
                paramFilters.push({
                  filter: 'Model',
                  selection: String(value),
                  backEndFilter: 'model'
                });
                this.searchbarComponent.selectFilterProgramatically('Model', String(value), 'model');
                triggerShowFilterBar = true;
              } else if (param === 'Category'){
                paramFilters.push({
                  filter: 'Category',
                  selection: String(value),
                  backEndFilter: 'category'
                });
                this.searchbarComponent.selectFilterProgramatically('Category', String(value), 'category');
                triggerShowFilterBar = true;
              } else if (param === 'Upper Make'){
                paramFilters.push({
                  filter: 'Upper Make',
                  selection: String(value),
                  backEndFilter: 'upperMake'
                });
                this.searchbarComponent.selectFilterProgramatically('Upper Make', String(value), 'upperMake');
                triggerShowFilterBar = true;
              } else if (param === 'Upper Model'){
                paramFilters.push({
                  filter: 'Upper Model',
                  selection: String(value),
                  backEndFilter: 'upperModel'
                });
                this.searchbarComponent.selectFilterProgramatically('Upper Model', String(value), 'upperModel');
                triggerShowFilterBar = true;
              } else if (param === 'Page') {
                this.page = parseInt(String(value), 10);
              }
            }

            this.appliedSortFilters.filterResults = paramFilters;

            if (triggerShowFilterBar) {
              this.searchbarComponent.showCollapsedFilters();
            }

            this.loading = true;
            this.pageChange();
          }, 0);
        });
      });

    }
  );
  }

  public pageChange(): void {
    this.loading = true;
    this.search();
    window.scroll(0, 0);
  }

  public applyFilterSortOptions(data: SortFilterOutput): void {
    this.appliedSortFilters = data;
    this.search(this.appliedSortFilters);
  }

  public exportResults(): void {
    console.log('EXPORT');
  }

  public searchDrafts(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if(this.appliedSortFilters){
      this.appliedSortFilters.searchTerm = searchTerm;
    } else {
      this.appliedSortFilters = {
        searchTerm
      }
    }
    this.search();
  }

  public search(searchOptions?: SortFilterOutput): void {
    this.loading = true;
    const newQueryParams: Params = {};

    // update the URL params

    const filterResultArray = this.appliedSortFilters;

    if (filterResultArray) {
      if(filterResultArray.filterResults){
        filterResultArray.filterResults.forEach(filter => {
          newQueryParams[filter.filter] = filter.selection;
        });
      }
      if (filterResultArray.sortResults) {
        newQueryParams.Sort = filterResultArray.sortResults;
      }

      if (filterResultArray.sortDirection) {
        newQueryParams.Direction = filterResultArray.sortDirection;
      }
    }

    newQueryParams.Page = this.page;
    if (this.searchTerm){
      newQueryParams.Search = this.searchTerm;
    }
    newQueryParams.Caller = this.accountId;

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: newQueryParams
      }
    );

    this.draftsService.queryDraftItems(this.accountId, this.appliedSortFilters).subscribe(items => {
      if (this.appliedSortFilters){
        if (this.appliedSortFilters.backEndSortResult){
          let sortItems = items.records;
          let sortDirection = this.appliedSortFilters.sortDirection;
          let sortBy = this.appliedSortFilters.backEndSortResult;
          sortItems.sort((a, b) => {
            switch (sortBy) {
              case 'category':
                if (sortDirection === 'asc'){
                  return a.category?.localeCompare(b.category);
                } else {
                  return b.category?.localeCompare(a.category);
                }
                break;
              case 'make':
                if (sortDirection === 'asc'){
                  return a.make?.localeCompare(b.make);
                } else {
                  return b.make?.localeCompare(a.make);
                }
                break;
              case 'model':
                if (sortDirection === 'asc'){
                  return a.model?.localeCompare(b.model);
                } else {
                  return b.model?.localeCompare(a.model);
                }
                break;
              case 'upperMake':
                if (sortDirection === 'asc'){
                  return a.upperMake?.localeCompare(b.upperMake);
                } else {
                  return b.upperMake?.localeCompare(a.upperMake);
                }
                break;
              case 'upperModel':
                if (sortDirection === 'asc'){
                  return a.upperModel?.localeCompare(b.upperModel);
                } else {
                  return b.upperModel?.localeCompare(a.upperModel);
                }
                break;
            }
          });
        }
      }
      this.draftItems = items;
      // if (items.lastEvaluatedKey) {
      //   this.hasMore = true;
      //   this.lastEvaluatedKey = items.lastEvaluatedKey;
      // } else {
        this.hasMore = false;
      //}

      if(this.searchTerm){
        this.searchbarComponent.setSearchText(this.searchTerm);
      }

      this.loading = false;
    });
  }
}

export interface DraftItemData {
  userId: string;
  itemId: string;
  itemType: string;
  category: string;
  unitNumber: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  lotNumber: string;
  sale: string;
  dateCreated: string;
  location: string;
  catalogDescription: string;
  currentStep: string;
  submitTimestamp: string;
}

export enum ItemCurrentStep {
  VEHICLE_INFO = 'Vehicle Information',
  VEHICLE_SPECS = 'Vehicle Specifications',
  UNIT_INFO = 'Unit Info',
  LOCATION = 'Location',
  PICTURES = 'Pictures',
  CONFIRMATION = 'Confirmation'
}


export enum ItemFilters {
  CATEGORY = 'Category',
  MAKE = 'Make',
  MODEL = 'Model',
  UPPER_MAKE = 'Upper Make',
  UPPER_MODEL = 'Upper Model'
}

export enum ItemSorts {
  CATEGORY = 'Category',
  MAKE = 'Make',
  MODEL = 'Model',
  UPPER_MAKE = 'Upper Make',
  UPPER_MODEL = 'Upper Model'
}
