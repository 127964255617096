import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemData} from '../../pages/items/items.component';
import {GetFaIcon} from '../../../Shared/functions/icon-helpers';
import {CheckFileExist} from '../../../Shared/functions/file-exists-helpers';
import {MobileItemModelComponent} from '../mobile-item-model/mobile-item-model.component';
import {NgbModalRef, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {download} from '../../../Shared/functions/download-helpers';
import { InvoiceService } from 'src/app/Invoices/services/invoice.service';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../../Shared/services/google-analytic.service';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { GoogleAnalyticDirective } from '../../../Shared/directives/google-analytic.directive';

@Component({
  selector: `item-card`,
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  imports: [CommonModule, FaIconComponent, GoogleAnalyticDirective, CurrencyPipe]
})
export class ItemCardComponent implements OnInit {

  @Input() item: ItemData;
  // tslint:disable-next-line:no-output-native
  @Output() select: EventEmitter<number> = new EventEmitter();

  public faQuestion = GetFaIcon('question', 'fas');
  public faImage = GetFaIcon('image', 'fas');
  public faFileContract = GetFaIcon('file-contract', 'fas');
  public faReceipt = GetFaIcon('receipt', 'fas');
  public showCatalogDescription = false;
  public imageExists: boolean = false;
  public isBuyerItemSearch: boolean;
  public downloadingInvoicePdf: boolean;

  constructor(private modalService: NgbModal,
              private router: Router,
              private invoiceService: InvoiceService,
              private googleAnalyticService: GoogleAnalyticService) { }

  ngOnInit(): void {
    this.isBuyerItemSearch = this.router.url.indexOf('purchased-items') >= 0;
    this.getImageUrl();
  }

  public showMobileModal(show: string): void{
    const modal: NgbModalRef = this.modalService.open(MobileItemModelComponent, {backdrop: 'static', keyboard: false});
    modal.componentInstance.show = show;
    modal.componentInstance.unitNumber = this.item.unitNumber;
    modal.componentInstance.itemId = this.item.itemId;

    if (show === 'image'){
      modal.componentInstance.imageSrc = this.item.imagePath;
    } else {
      modal.componentInstance.description = this.item.catalogDescription;
    }
  }

  public toggleCatalogDescription(): void {
    this.showCatalogDescription = !this.showCatalogDescription;
    if (this.showCatalogDescription) {
      const label = this.isBuyerItemSearch ? 'Purchased Items' : 'Consigned Items';
      const event: GoogleAnalyticEventObject = {
        category: 'Show Description',
        action: 'Click',
        label
      };
      this.googleAnalyticService.logAnalyticEvent(event, () => {});
    }
  }

  public clickDetail(itemId: number): void{
    const label = this.isBuyerItemSearch ? 'Purchased Items' : 'Consigned Items';
    const event: GoogleAnalyticEventObject = {
      category: 'Item Selection',
      action: 'Click',
      label
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {});
    this.select.emit(itemId);
  }

  public openPayout(payoutId: number): void {
    this.router.navigate(['/dashboard/payouts/', payoutId]);
  }

  public getImageUrl(): void {
    if(this.item.imagePath){
      if(CheckFileExist(this.item.imagePath)){
        this.imageExists = true;
      } else {
        this.imageExists = false;
      }
    }
  }

  public downloadInvoicePdf(accountId: number, invoiceId: number): void {
    this.downloadingInvoicePdf = true;

    const event: GoogleAnalyticEventObject = {
      category: 'Download Invoice',
      action: 'Click',
      label: 'Purchased Items'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
      this.invoiceService.downloadInvoicePdf(accountId, invoiceId).subscribe(data => {
        const fileName = `Bill_Of_Sale_${invoiceId}`;
        const fileType = 'application/pdf';
        download(fileName, fileType, data);
        this.downloadingInvoicePdf = false;
      });
    });
  }
}
