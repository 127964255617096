import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Router } from '@angular/router';
import { DraftsService, RDSDraftItem } from '../../services/drafts.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { GoogleAnalyticDirective } from '../../../Shared/directives/google-analytic.directive';
import { GetFaIcon } from '../../../Shared/functions/icon-helpers';

@Component({
  selector: 'draft-card',
  templateUrl: './draft-card.component.html',
  styleUrls: ['./draft-card.component.scss'],
  imports: [GoogleAnalyticDirective, FaIconComponent]
})
export class DraftCardComponent implements OnInit {

  @Input() draftItem: RDSDraftItem;
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() deleteDraft = new EventEmitter();
  public faTrash = GetFaIcon('trash', 'fas');
  public draftStep: string;

  constructor(private modalService: NgbModal, private router: Router, public draftsService: DraftsService) {
  }

  ngOnInit(): void {
    switch (this.draftItem.currentStep) {
      case 1:
        this.draftStep = this.draftItem.titled ? 'Vehicle Info' : 'Item Info';
      break;
      case 2:
        this.draftStep = this.draftItem.titled ? 'Vehicle Specs' : 'Location';
      break;
      case 3:
        this.draftStep = this.draftItem.titled ? 'Unit Info' : 'Pictures';
      break;
      case 4:
        this.draftStep = this.draftItem.titled ? 'Location' : 'Confirmation';
      break;
      case 5:
        this.draftStep = 'Pictures';
      break;
      case 6:
        this.draftStep = 'Confirmation';
      break;
      default:
        this.draftStep = 'Unknown';
      break;
    }
  }

  public showMobileModal(show: string): void {
    const modal: NgbModalRef = this.modalService.open(DeleteModalComponent, {backdrop: 'static', keyboard: false});
    modal.componentInstance.show = show;
    modal.componentInstance.unitNumber = this.draftItem.unitNumber;
    modal.componentInstance.itemId = this.draftItem.itemId;
    modal.componentInstance.draftItem = this.draftItem;
    modal.result.then(() => {
        this.draftsService.deleteItem(this.draftItem).subscribe(() => {
          this.deleteDraft.emit();
        });
    });
  }

  public navigateToCurrentStep(itemId: string): void {
    this.select.emit(itemId);
  }

  public navigateToDraftPage(): void {
    this.draftsService.activeDraft = this.draftItem;
    console.log('Navigating to submit-items');
    this.router.navigate(['dashboard/submit-items'], {queryParams: {Draft: true}});
  }
}
