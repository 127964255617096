import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationStepData} from '../../models/navigation-step-data';
import {FormGroup} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';
import {IItem, ILocation, IUnit, IVehicle} from '../../models/form-models';
import { AuctionItemInput, SaveItemVariables, SubmitItemService} from '../../services/submit-item.service';
import {ContactService} from '../../../Shared/services/contact.service';
import {CompanySelectionService} from '../../../Shared/services/company-selection.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CompanySelectionModalComponent} from '../../../Shared/components/company-selection-modal/company-selection-modal.component';
import {DraftsService, RDSDraftItem} from '../../../item-drafts/services/drafts.service';
import {SubmitModalComponent} from '../submit-modal/submit-modal.component';
import { AuctionItem } from '../../../generated/graphql';
import { CommonModule } from '@angular/common';
import { GoogleAnalyticDirective } from '../../../Shared/directives/google-analytic.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-base-step-wizard',
  templateUrl: './base-step-wizard.component.html',
  styleUrls: ['./base-step-wizard.component.scss'],
  imports: [CommonModule, GoogleAnalyticDirective, RouterModule, FaIconComponent]
})
export class BaseStepWizardComponent implements OnInit {

  @Input()
  public navigationSteps: NavigationStepData[] = [];

  @Input()
  public parentForm: FormGroup;

  @Input()
  public titled: boolean;

  @Input()
  public draftItem: RDSDraftItem;

  @Output()
  public selectStep: EventEmitter<number> = new EventEmitter<number>();

  public progressWidth = '0%';
  public selectedStep = 1;
  public stepCount;
  public draftItemsTableData: Array<RDSDraftItem> = [];
  public loadingDraftItems = true;
  public hasMore = false;
  public submitted: boolean;
  private singleSegmentWidth;
  private item: IItem;
  private vehicle: IVehicle;
  private unit: IUnit;
  private location: ILocation;

  constructor(private router: Router, private draftService: DraftsService, private companySelectionService: CompanySelectionService, private submitItemService: SubmitItemService, private contactService: ContactService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.stepCount = this.navigationSteps.length;
    const totalSegments = this.stepCount - 1;
    this.singleSegmentWidth = 100 / totalSegments;
    if (this.draftItem) {
      this.setStep(this.draftItem.currentStep);
    }
  }

  public setStep(step: number): void {
    this.selectedStep = step;
    this.selectStep.emit(this.selectedStep);

    const segmentsToShow = this.selectedStep - 1;
    this.progressWidth = (segmentsToShow * this.singleSegmentWidth) + '%';
  }

  public clearDraftTableSettings(): void {
    this.draftItemsTableData = [];
  }

  public queryDraftItems(): void {
    const custId = this.draftService.accountIds;
    this.loadingDraftItems = true;
    this.draftService.queryDraftItems(custId).subscribe(res => {
      if (res && res.results) {
        this.draftItemsTableData = this.draftItemsTableData.concat(res.results);
        // if (res.lastEvaluatedKey) {
        //   this.hasMore = true;
        //   this.lastEvaluatedKey = res.lastEvaluatedKey;
        // } else {
          this.hasMore = false;
       // }
      }
      this.loadingDraftItems = false;
    });
  }

  public saveAndExit(): void {

    if (this.parentForm.controls.vehicleInfoFormGroup) {
      this.vehicle = {...this.parentForm.controls.vehicleInfoFormGroup.value};
    }

    if (this.parentForm.controls.vehicleSpecsFormGroup) {
      this.vehicle = {...this.vehicle, ...this.parentForm.controls.vehicleSpecsFormGroup.value};
    }

    if (this.parentForm.controls.unitInfoFormControlGroup) {
      this.unit = {...this.parentForm.controls.unitInfoFormControlGroup.value};
    }

    if (this.parentForm.controls.locationFormGroup) {
      this.location = {...this.parentForm.controls.locationFormGroup.value};
    }

    if (this.parentForm.controls.itemInfoFormGroup) {
      this.item = {...this.parentForm.controls.itemInfoFormGroup.value};
    }
    let id;
    id = this.companySelectionService.selectedCompany.id;

    const rdsDraft: RDSDraftItem = {
      id: this.draftItem ? this.draftItem.id : Number(),
      userId: id.toString(),
      currentStep: this.selectedStep,
      submitTimestamp: this.draftItem ? this.draftItem.submitTimestamp : String(Date.now()),
      itemType: this.titled ? 'Titled' : 'Misc',
      parentCategory: this.titled ? this.vehicle.category : this.item.category,
      category: this.titled ? this.vehicle.subcategory : this.item.subcategory,
      vin: this.titled ? this.vehicle.vin : '',
      year: this.titled ? this.vehicle.year : this.item.year,
      make: this.titled ? this.vehicle.make : this.item.make,
      model: this.titled ? this.vehicle.model : this.item.model,
      sellerFleetNumber2: this.titled ? this.vehicle.sellerVehicleNumber : '',
      plateNumber: this.titled ? this.vehicle.licensePlateNumber : '',
      engine: this.titled ? this.vehicle.engine : '',
      fuel: this.titled ? this.vehicle.fuel : '',
      transmission: this.titled ? this.vehicle.transmission : '',
      odometer: this.titled ? this.vehicle.odometer : '',
      hours: this.titled ? this.vehicle.hours : '',
      optionalEquipment: this.titled ? this.vehicle.optionalEquipment : this.item.optionalEquipment,
      airConditioning: this.titled ? this.vehicle.airConditioning : null,
      unitNumber: this.titled ? this.vehicle.unitNumber : this.item.unitNumber,
      upperSerialNumber: this.titled ? this.unit.serialNumber : '',
      upperMake: this.titled ? this.unit.make : null,
      upperModel: this.titled ? this.unit.model : null,
      upperFuel: this.titled ? this.unit.fuel : '',
      height: this.titled ? this.unit.height : '',
      capacity: this.titled ? this.unit.capacity : '',
      capacityUnit: this.titled ? this.unit.capacityUnit : '',
      capacityType: this.titled ? this.unit.capacity : '',
      mounted: this.titled ? this.unit.mounted : '',
      upperOptionalEquipment: this.titled ? this.unit.optionalEquipment : '',
      itemNotes: this.titled ? this.unit.notes : '',
      offSitePhysicalAddress: this.location.addressLine1,
      offSitePhysicalCity: this.location.city,
      offSitePhysicalState: this.location.state,
      offSitePhysicalZip: this.location.zip,
      taxCounty: this.location.county,
      titled: this.titled
    };
    if (this.draftItem === null) {
      this.draftService.saveItem(rdsDraft).subscribe(() => {
        this.clearDraftTableSettings();
        this.queryDraftItems();
        console.log('navigating to item-drafts from bsae-step-wizard 1');
        this.router.navigateByUrl('/dashboard/item-drafts');
      });
    } else {
      this.draftService.updateItem(rdsDraft).subscribe(() => {
        this.clearDraftTableSettings();
        this.queryDraftItems();
        console.log('navigating to item-drafts from bsae-step-wizard 2');
        this.router.navigateByUrl('/dashboard/item-drafts');
      });
    }
  }

  public showCompanySelectionModal(): void {
    this.modalService.open(CompanySelectionModalComponent, {backdrop: 'static', keyboard: false});
  }

  public submit(): void {
    console.log('inside submit');
    let auctionItemId;

    const variables = new SaveItemVariables();
    const auctionItem = this.convertFormForSubmission();
    if (auctionItem) {
      // Initialize submission modal, tell it that it is submitting the base item
      const modal: NgbModalRef = this.modalService.open(SubmitModalComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true
      });

      modal.componentInstance.photoCount = this.submitItemService.uploadQueue.length;
      modal.componentInstance.doneSubmitting = false;
      modal.componentInstance.submittingItem = true;

      let id;
      if (this.companySelectionService.selectedCompany.id[0]) {
        this.showCompanySelectionModal();
      } else {
        id = this.companySelectionService.selectedCompany.id;
        variables.accountId = id;
        variables.contactId = this.companySelectionService.selectedCompany.defaultContact;
        variables.auctionItem = auctionItem;

        this.submitItemService.saveAuctionItem(variables).subscribe((response: AuctionItem) => {
          if (response) {
            auctionItemId = response.id;
            // Tell modal it has finished uploading item, and is going to upload photos next
            modal.componentInstance.submittingItem = false;
            modal.componentInstance.submittingPhotos = true;

            // If there are photos attached, upload them, otherwise finish
            if (this.submitItemService.uploadQueue.length > 0) {
              console.log(`UPLOADING PHOTOS FOR ID: ${response.id} and SALE: ${response.saleId}`);
              this.submitItemService.saveAuctionPhotos(String(response.id), String(response.saleId)).then(res => {
                modal.componentInstance.submittingPhotos = false;
                modal.componentInstance.doneSubmitting = true;
                console.log('Photo uploads finished');
                console.log(new Date(Date.now()).toLocaleString());
                console.log(res);
              });
            } else {
              modal.componentInstance.submittingPhotos = false;
              modal.componentInstance.doneSubmitting = true;
            }

            if (auctionItemId) {
              modal.result.then(() => {
                console.log('Navigating to item-drafts from base-step-wizard 3');
                this.router.navigate(['/dashboard/items/details', auctionItemId]);
              });
            }

          } else {
            modal.componentInstance.doneSubmitting = true;
            modal.componentInstance.error = true;
          }
        });
        if (this.draftItem) {
          this.draftService.deleteItem(this.draftItem).subscribe(() => {});
        }
      }
    }
  }

  private convertFormForSubmission(): AuctionItemInput {
    if (!this.parentForm.valid) {
      this.parentForm.markAllAsTouched();
      this.submitted = true;
      return null;
    }

    if (this.parentForm.controls.vehicleInfoFormGroup) {
      this.vehicle = {...this.parentForm.controls.vehicleInfoFormGroup.value};
    }

    if (this.parentForm.controls.vehicleSpecsFormGroup) {
      this.vehicle = {...this.vehicle, ...this.parentForm.controls.vehicleSpecsFormGroup.value};
    }

    if (this.parentForm.controls.unitInfoFormControlGroup) {
      this.unit = {...this.parentForm.controls.unitInfoFormControlGroup.value};
    }

    if (this.parentForm.controls.locationFormGroup) {
      this.location = {...this.parentForm.controls.locationFormGroup.value};
    }

    if (this.parentForm.controls.itemInfoFormGroup) {
      this.item = {...this.parentForm.controls.itemInfoFormGroup.value};
    }
    const submitItemFields = new AuctionItemInput();
    if (this.titled) {
      submitItemFields.parentCategory = this.vehicle.category;
      submitItemFields.category = this.vehicle.subcategory;
      submitItemFields.vin = this.vehicle.vin;
      submitItemFields.year = this.vehicle.year;
      submitItemFields.make = this.vehicle.make;
      submitItemFields.model = this.vehicle.model;
      submitItemFields.unitNumber = this.vehicle.unitNumber;
      submitItemFields.sellerFleetNumber2 = this.vehicle.sellerVehicleNumber;
      submitItemFields.plateNumber = this.vehicle.licensePlateNumber;

      submitItemFields.engine = this.vehicle.engine;
      submitItemFields.fuel = this.vehicle.fuel;
      submitItemFields.transmission = this.vehicle.transmission;
      submitItemFields.odometer = this.vehicle.odometer;
      submitItemFields.hours = this.vehicle.hours;
      submitItemFields.optionalEquipment = this.vehicle.optionalEquipment;
      submitItemFields.airConditioning = this.vehicle.airConditioning;

      submitItemFields.upperSerialNumber = this.unit.serialNumber;
      submitItemFields.upperMake = this.unit.make;
      submitItemFields.upperModel = this.unit.model;
      submitItemFields.upperFuel = this.unit.fuel;
      submitItemFields.height = this.unit.height;
      submitItemFields.capacity = this.unit.capacity;
      submitItemFields.capacityType = this.unit.capacityUnit;
      submitItemFields.mounted = this.unit.mounted;
      submitItemFields.upperOptionalEquipment = this.unit.optionalEquipment;
      submitItemFields.itemNotes = this.unit.notes;

      submitItemFields.offSitePhysicalAddress = this.location.addressLine1;
      submitItemFields.offSitePhysicalCity = this.location.city;
      submitItemFields.offSitePhysicalState = this.location.state;
      submitItemFields.offSitePhysicalZip = this.location.zip;
      submitItemFields.taxCounty = this.location.county;
    } else {
      submitItemFields.parentCategory = this.item.category;
      submitItemFields.category = this.item.subcategory;
      submitItemFields.year = this.item.year;
      submitItemFields.make = this.item.make;
      submitItemFields.model = this.item.model;
      submitItemFields.unitNumber = this.item.unitNumber;
      submitItemFields.optionalEquipment = this.item.optionalEquipment;

      submitItemFields.offSitePhysicalAddress = this.location.addressLine1;
      submitItemFields.offSitePhysicalCity = this.location.city;
      submitItemFields.offSitePhysicalState = this.location.state;
      submitItemFields.offSitePhysicalZip = this.location.zip;
      submitItemFields.taxCounty = this.location.county;
    }
    return submitItemFields;
  }
}
