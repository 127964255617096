import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ForgotPWVariables, PasswordService } from '../../services/password.service';
import { ForgotPassword } from '../../../generated/graphql';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  imports: [CommonModule, ReactiveFormsModule]
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup;

  public success: boolean = false;

  constructor(private fb: FormBuilder, private passwordService: PasswordService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', Validators.required]
    });
  }

  public submit() {
    var input: ForgotPassword = {
      username: ""
    };
    input.username = this.form.controls["username"].value;

    this.passwordService.forgotPassword(input).subscribe(success => {
      this.success = success;
    });
  }

}
