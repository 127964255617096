import { Component, Input } from '@angular/core';
import { ScreenType, ScreenSizeService, ScreenData } from '../../../Shared/services/screen-size.service';
import { RouterModule} from "@angular/router";
import { SideNavbarComponent } from '../../../Shared/components/side-navbar/side-navbar.component';
import { TopNavbarComponent } from '../../../Shared/components/top-navbar/top-navbar.component';
import { FooterComponent } from '../../../Shared/components/footer/footer.component';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';

const MOBILE_TOP_GAP = 55;
const TABLET_TOP_GAP = 66;
const DESKTOP_TOP_GAP = 91;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  imports: [TopNavbarComponent, MatSidenavContainer, MatSidenav, SideNavbarComponent, MatSidenavContent, RouterModule, FooterComponent]
})
export class IndexComponent {
  public sideNavExpanded = true;
  public isMobile: boolean;
  public screen_size: ScreenType;

  @Input() public liteVersion: boolean;

  constructor(public screenSize: ScreenSizeService) {
    this.screenSize.$screenSize.subscribe((data: ScreenData) => {
      this.screen_size = data.screenType;
      this.isMobile = this.screen_size === ScreenType.Mobile ? true : false;
    });

  }

  public sideNavExpand() {
    this.sideNavExpanded = !this.sideNavExpanded;
  }

  public getTopGap() {
    if(this.screen_size === ScreenType.Mobile) {
      return MOBILE_TOP_GAP;
    } else if(this.screen_size === ScreenType.Tablet) {
      return TABLET_TOP_GAP;
    } else {
      return DESKTOP_TOP_GAP;
    }
  }

}
