import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import {BehaviorSubject } from 'rxjs';

/**
 * Pagination Component
*/

const DEFAULT_EITHER_SIDE = 2;

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  // styleUrls: ['./pagination.component.html'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule]
})
export class PaginationComponent implements OnChanges {
    /**
     * Component @Input hasFirstAndLast
     * Show First and Last page selection buttons, default false
     */
    @Input() public hasFirstAndLast: boolean = false;
  /**
     * Component @Input pageSize
     * Default number of pages to display
     */
  @Input() public pageSize: number;
  /**
     * Component @Input numberOfItems
     * Total data record count
     */
  @Input() public numberOfItems: number;
  /**
     * Component @Input index
     * Default page to trigger on load
     */
  @Input() public index: number;
  /**
     * Component @Output paging
     * One way event binding for paging clicks
     */
  @Output() public paging: EventEmitter<number>;
  /**
     * @Observable
     * asyncronize data to view when it changes
     */
  public pages$: BehaviorSubject<number[]>;

  constructor() {
    this.pages$ = new BehaviorSubject<number[]>([]);
    this.paging = new EventEmitter<number>();
  }

  /**
      * Listens for changes of all component @Inputs
      * @param changes: SimpleChanges
      */
  ngOnChanges(): void {
    this.pages$.next(this._pages());
  }

  /**
     * Calculate total number of pages required
     * <<Return>> number
     */
  public numberOfPages(): number {
    const length = this.numberOfItems;
    const size = this.pageSize;
    return Math.ceil(length / size);
  }
  /**
     * Triggers asc of index
     * @param index: number
     * <<Return>> number
     */
  public next(): number {
    if (this.numberOfPages() === this.currentPage()) {
      return;
    }
    this.index += 1;
    this.pages$.next(this._pages());
    this.paging.emit(this.index);
  }
  /**
     * Triggers desc of index
     * <<Return>> number
     */
  public previous(): number {
    if (this.index === 0) {
      return;
    }
    this.index -= 1;
    this.pages$.next(this._pages());
    this.paging.emit(this.index);
  }

  /**
     * Triggers jump to last index value
     * <<Return>> void
     */
  public last(): void {
    if (this.numberOfPages() === this.currentPage()) {
      return;
    }
    this.index = this.numberOfPages() - 1;
    this.pages$.next(this._pages());
    this.paging.emit(this.index);
  }
  /**
     * Trigger jump to first index value
     * <<Return>> void
     */
  public first(): void {
    if (this.index === 0) {
      return;
    }
    this.index = 0;
    this.pages$.next(this._pages());
    this.paging.emit(this.index);
  }

  public firstNoEmit(): void{
    if (this.index === 0) {
      return;
    }
    this.index = 0;
    this.pages$.next(this._pages());
  }
  /**
     * Get current paging index value
     * <<Return>> number
     */
  public currentPage(): number {
    return this.index + 1;
  }
  /**
     * Jump to selected index value
     * @param page: number
     * <<Return>> void
     */
  public goToPage(page: number): void {
    this.index = page - 1;
    this.pages$.next(this._pages());
    this.paging.emit(this.index);
  }
  /**
     * Applies css to selected index value to denote it as active
     * @param pagenumber: number
     * <<Return>> string
     */
  public isPageActive(pagenumber: number): string {
    if (pagenumber === this.currentPage()) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  /**
     * Applies css to end buttons to establish data boundaries
     * @param startend: string
     * <<Return>> string
     */
  public isEndBtnDisabled(startend: string): string {
    const current = this.currentPage();
    const pages = this.numberOfPages();
    switch (startend.toLowerCase()) {
      case 'first':
      case 'previous':
        return current <= 1 ? 'disabled' : '';
      case 'next':
      case 'last':
        return pages === current ? 'disabled' : '';
      default:
        return '';
    }
  }

  /**
     * Facade to generate paging index array
     * <<Return>> number
     */
  private _pages(): number[] {
    return this._getIndexCenteredPaginationArray(this.currentPage(), this.numberOfPages(), DEFAULT_EITHER_SIDE);
  }

  // public mobilePages() {
  //   return this.getIndexCenteredPaginationArray(this.currentPage(), this.numberOfPages(), DEFAULT_MOBILE_EITHER_SIDE);
  // }

  /**
     * Generates paging index array
     * @param currentPage: number
     * @param numberOfPages: number
     * @param numberOnEitherSide: number
     * <<Return>> number
     */
  private _getIndexCenteredPaginationArray(currentPage, numberOfPages, numberOnEitherSide): number[] {
    const upperBound = Math.min(currentPage + numberOnEitherSide, numberOfPages);
    const lowerBound = Math.max(currentPage - numberOnEitherSide, 1);

    const pages: number[] = [];
    for (let i = lowerBound; i <= upperBound; i++) {
      pages.push(i);
    }
    return pages;
  }
}
