import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
  imports: [CommonModule]
})
export class CustomModalComponent {

  @Input() modalContent;

  constructor(public activeModal: NgbActiveModal, public router: Router) { }

}
