import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GetInventoryManagementDetailsQuery} from "../../../generated/graphql";
import { InventoryManagementCardComponent } from 'src/app/Dashboard/components/inventory-management-card/inventory-management-card.component';

@Component({
  selector: 'inventory-management',
  templateUrl: './inventory-management.component.html',
  styleUrls: ['./inventory-management.component.scss'],
  imports: [InventoryManagementCardComponent]
})
export class InventoryManagementComponent implements OnInit {

  @Input()
  inventoryManagementDetails: GetInventoryManagementDetailsQuery['inventoryManagementDetails'];

  @Output()
  quickFilterClicked: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  quickFilterAction(val: string): void {
    this.quickFilterClicked.emit(val);
  }
}
