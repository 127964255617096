import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  AGGREGATE_COMPANY_NAME,
  CompanySelectionService,
  UserAggregateCompany,
  UserCompany
} from '../../services/company-selection.service';
import {Subscription} from 'rxjs';
import {GoogleAnalyticEventObject, GoogleAnalyticService} from '../../services/google-analytic.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-company-selection-modal',
  templateUrl: './company-selection-modal.component.html',
  styleUrls: ['./company-selection-modal.component.scss'],
  imports: [CommonModule]
})
export class CompanySelectionModalComponent implements OnInit, OnDestroy {
  @Input()
  isMobile: boolean;

  public companies: Array<(UserCompany | UserAggregateCompany)>;
  public selectedCompany: UserCompany| UserAggregateCompany;
  public companySelected = false;
  private companySubscription: Subscription;

  constructor(public activeModal: NgbActiveModal, public companyService: CompanySelectionService, private googleAnalyticService: GoogleAnalyticService) {
  }

  ngOnInit(): void {
    this.companySubscription = this.companyService.$userCompanies.subscribe(companies => {
      if (companies.length > 0) {
        const tempCompanies = [...companies];
        const aggregateView = tempCompanies.find(comp => comp.name === AGGREGATE_COMPANY_NAME);
        tempCompanies.splice(tempCompanies.indexOf(aggregateView), 1);
        this.companies = tempCompanies;
        this.selectedCompany = this.companies[0];
      } else {
        this.companies = companies;
      }
    });
  }

  ngOnDestroy(): void {
    this.companySubscription.unsubscribe();
  }

  public swapCompany(): void {
    this.companyService.selectCompany(this.selectedCompany.name);
    const event: GoogleAnalyticEventObject = {
      category: 'Company Selector',
      action: 'Company Selected',
      label: 'Submit'
    };
    this.googleAnalyticService.logAnalyticEvent(event, () => {
    });
    this.companySelected = true;
    this.companyService.temporarySelection = true;
    this.activeModal.close();
  }

  public companyChange(event){
    this.selectedCompany = this.companies[event.target.selectedIndex];
  }
}
