<div>
  <div class="row d-flex align-items-center">

    <!-- Unit Details -->
    <div class="col-lg-12 col-12">
      <div class="row">
        <div class="col-6 col-lg-12">
          <a (click)="navigateToDraftPage()" [GA]="{event: {category: 'Select Draft', action: 'Click', label: 'Item Drafts'}}"><h5 class="mb-1 alt-link">Unit #: {{ draftItem.unitNumber }}</h5></a>
        </div>
        <div class="col-6 d-lg-none text-right">
          <fa-icon (click)="showMobileModal('image')" [icon]="faTrash" size="2x" class="text-danger"></fa-icon>
        </div>
        <div class="col-lg-5 col-12">
          <p class="mb-1"><b>Category:</b> {{ draftItem.category }}</p>
          <p class="mb-1"><b>VIN:</b> {{ draftItem.vin }}</p>
          <p class="mb-1"><b>Year:</b> {{ draftItem.year }}</p>
          <p class="mb-1"><b>Make:</b> {{ draftItem.make }}</p>
          <p class="mb-1"><b>Model:</b> {{ draftItem.model }}</p>
        </div>
        <div class="col-lg-4 col-12">
          <p class="mb-1"><b>Item Type:</b> {{ draftItem.itemType }}</p>
          <p class="mb-1"><b>Date Saved:</b> {{ draftItem.dateCreated }}</p>
        </div>
        <div class="col-lg-3 col-12">
          <div class="d-flex flex-wrap justify-content-center align-content-center row">
            <div class="col-lg-12">
              <h5 class="mb-1 text-start text-lg-center inline">
                <b>Step:</b>
              </h5>
              <p class="mb-1 ml-1 text-start text-lg-center d-lg-none inline">{{ this.draftStep }}</p>
            </div>
            <div class="col-lg-12 d-none d-lg-block">
              <p class="mb-1 text-start text-lg-center">{{ this.draftStep }}</p>
            </div>
            <div class="mt-1 d-none d-lg-block text-lg-center">
              <button type="button" class="btn btn-danger" (click)="showMobileModal('delete draft')">Delete Draft</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
