import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {RDSDraftItem} from '../../../../item-drafts/services/drafts.service';
import {Observable} from 'rxjs';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-unit-info-step',
  templateUrl: './unit-info-step.component.html',
  styleUrls: ['./unit-info-step.component.scss'],
  imports: [CommonModule, ReactiveFormsModule]
})
export class UnitInfoStepComponent implements OnInit {

  @Input() public parentForm: FormGroup;
  @Input() public showForm: boolean;
  @Input() public dropdownValues: GetDropdownValuesQuery;
  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  @Input() public loading: boolean;
  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;

  public form: FormGroup;
  public models: string[] = [];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      serialNumber: ['', Validators.maxLength(55)],
      makeAndModel: [''],
      make: [''],
      model: [{value: '', disabled: true}],
      fuel: [''],
      height: ['', [Validators.maxLength(12), Validators.pattern('^[\\d]+$')]],
      capacity: ['', [Validators.maxLength(6), Validators.pattern('^[\\d]+$')]],
      capacityUnit: [{value: '', disabled: true}],
      mounted: [''],
      optionalEquipment: ['', Validators.maxLength(1000)],
      notes: ['', Validators.maxLength(2000)],
    });

    this.ddvSubject.subscribe(values => {
      this.dropdownValues = values;
      this.loading = values == null;
      if (!this.loading && this.draft) {
        this.form.setValue({
          serialNumber: this.draft.parentCategory,
          makeAndModel: this.draft.upperMake ? this.dropdownValues.upperMakeAndModel.find(filter => filter.make === this.draft.upperMake) : null,
          make: this.draft.upperMake ? this.dropdownValues.upperMakeAndModel.find(filter => filter.make === this.draft.upperMake).make : null,
          model: this.draft.upperModel,
          fuel: this.draft.fuel,
          height: this.draft.height,
          capacity: this.draft.capacity,
          capacityUnit: this.draft.capacity ? this.dropdownValues.capacity.find(filter => filter.name === this.draft.capacityUnit).name : '',
          mounted: this.draft.mounted,
          optionalEquipment: this.draft.optionalEquipment,
          notes: this.draft.itemNotes
        });
      }
    });

    this.form.get('makeAndModel').valueChanges.subscribe(value => {
      this.form.get('make').setValue('');
      this.form.get('model').setValue('');
      if (value) {
        this.form.get('make').setValue(value.make);
        this.models = value.models;
        this.form.get('model').enable();
      } else {
        this.form.get('model').disable();
      }
    });

    this.form.get('capacity').valueChanges.subscribe(value => {
      if (value) {
        this.form.get('capacityUnit').enable();
      } else {
        this.form.get('capacityUnit').disable();
      }
    });

    this.parentForm.addControl('unitInfoFormControlGroup', this.form);
  }
}
