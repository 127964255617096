import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {SubmitItemService} from '../../../services/submit-item.service';
import {Subcategory} from '../../../models/submit-item-gql-models';
import { RDSDraftItem } from '../../../../item-drafts/services/drafts.service';
import { Observable } from 'rxjs';
import { GetDropdownValuesQuery } from '../../../../generated/graphql';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-vehicle-info-step',
  templateUrl: './vehicle-info-step.component.html',
  styleUrls: ['./vehicle-info-step.component.scss'],
  imports: [CommonModule, ReactiveFormsModule]
})
export class VehicleInfoStepComponent implements OnInit {

  @Input() public parentForm: FormGroup;
  @Input() public showForm: boolean;
  @Input() public dropdownValues: GetDropdownValuesQuery;
  @Input() public ddvSubject: Observable<GetDropdownValuesQuery>;
  @Input() public isDraft = false;
  @Input() public draft: RDSDraftItem;

  public loading = true;
  public form: FormGroup;
  public years: string[];
  public filteredSubcategories: GetDropdownValuesQuery['subcategories'];
  public models: string[] = [];

  constructor(private fb: FormBuilder,
              private submitItemService: SubmitItemService) { }

  ngOnInit(): void {
    this.years = this.submitItemService.getYearList();
    this.form = this.fb.group({
      category: [''],
      subcategory: [{value: '', disabled: true}],
      vin: ['', [Validators.pattern('^[0-9a-zA-Z-]+$'), Validators.minLength(17), Validators.maxLength(17)]],
      year: [''],
      makeAndModel: [''],
      make: [''],
      model: [{value: '', disabled: true}],
      unitNumber: [''],
      sellerVehicleNumber: [''],
      licensePlateNumber: ['']
    });


    this.ddvSubject.subscribe(values => {
      this.dropdownValues = values;
      this.loading = values == null;
      if (!this.loading && this.draft) {
        this.form.setValue({
          category: this.draft.parentCategory,
          subcategory: this.draft.category,
          vin: this.draft.vin,
          year: this.draft.year,
          makeAndModel: this.draft.make ? this.dropdownValues['makeAndModel'].find(filter => filter.make === this.draft.make) : null,
          make: this.draft.make ? this.dropdownValues['makeAndModel'].find(filter => filter.make === this.draft.make).make : null,
          model: this.draft.model,
          unitNumber: this.draft.unitNumber,
          sellerVehicleNumber: this.draft.sellerFleetNumber2,
          licensePlateNumber: this.draft.plateNumber
        });
      }
    });



    this.form.get('category').valueChanges.subscribe(category => {
      const subcategoryControl = this.form.get('subcategory');
      subcategoryControl.setValue('');
      if (category) {
        this.filteredSubcategories = this.dropdownValues['subcategories']
          .filter(x => x['parentCategoryName'] === category);
        subcategoryControl.enable();
      } else {
        subcategoryControl.disable();
      }
    });

    this.form.get('makeAndModel').valueChanges.subscribe(value => {
      this.form.get('make').setValue('');
      this.form.get('model').setValue('');
      if (value) {
        this.form.get('make').setValue(value.make);
        this.models = value.models;
        this.form.get('model').enable();
      } else {
        this.form.get('model').disable();
      }
    });

    this.parentForm.addControl('vehicleInfoFormGroup', this.form);
  }
}
