import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, QueryList, ContentChildren, OnDestroy } from '@angular/core';
import { TabContentDirective } from '../../directives/tab-content.directive';
import { ScreenSizeService, ScreenType } from '../../services/screen-size.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { GoogleAnalyticDirective } from '../../directives/google-analytic.directive';

/*
*
* Component is currently limited to a max of 5 tabs, this can be fixed at a later date if needed
*
*/

@Component({
  selector: 'tab-engine',
  templateUrl: './tab-component.component.html',
  styleUrls: ['./tab-component.component.scss'],
  imports: [CommonModule, GoogleAnalyticDirective]
})
export class TabComponentComponent implements OnInit, OnDestroy {

  @Input() activeTabID: string;
  @ContentChildren(TabContentDirective) public tabChildren: QueryList<TabContentDirective>;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public isDesktop: boolean = false;
  public screenSize$: Subscription;

  constructor(private screenSize: ScreenSizeService) {
    this.screenSize$ = this.screenSize.$screenSize.subscribe(size => {
      this.isMobile = false;
      this.isTablet = false;
      this.isDesktop = false;
      switch (size.screenType) {
        case ScreenType.Mobile:
          this.isMobile = true;
        break;

        case ScreenType.Tablet:
          this.isTablet = true;
        break;

        case ScreenType.Desktop:
          this.isDesktop = true;
        break;

        default:
          this.isDesktop = true;
          break;
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.screenSize$.unsubscribe();
  }

  changeTab(tabID: string) {
    this.activeTabID = tabID;
  }

}
